import { put, takeLatest, call, select } from 'redux-saga/effects';

// actions
import actions from './actions';
import ToastAction from 'src/store/Toast/actions';

// services
import * as CompanyServices from 'src/servicesV2/company';

// selectors
import selectors from './selectors';

//types
import { ManageAccessAction, ManageAccessActionType } from './types';


export function* fetchAllCompanyUsers() {
  try {
    //fetch list
    const limit: number = yield select(selectors.getLimit);
    const cursor: string = yield select(selectors.getCursor);
    const search: string = yield select(selectors.getSearch);
    const searchField: string = yield select(selectors.getSearchField);
    const order_by: string = yield select(selectors.getOrderBy);
    const order_by_direction: string = yield select(selectors.getOrderByDirection);
    const company_id: string | undefined = yield select(
      selectors.getManageAccessTableCompanyId
    );
    if (!company_id) return;
    const only_external: boolean = yield select(selectors.getIsExternal);
    yield put(actions.setIsManageAccessTableLoading(true));
    const response = yield call(
      CompanyServices.fetchAllCompanyUsers,
      limit,
      cursor,
      search,
      searchField,
      order_by_direction,
      order_by,
      company_id,
      only_external
    );
    yield put(actions.setAllCompanyUser(response));
    //clear revoke list
    yield put(actions.setRevokeUserList([]));
  } catch (e: any) {
    yield put(actions.setError(e));
  } finally {
    yield put(actions.setIsManageAccessTableLoading(false));
  }
}

export function* revokeSingleUserAccess({ payload }: ManageAccessAction) {
  try {
    //revoke access
    if (!payload || !payload.revoke_single_user_id) return;
    const { revoke_single_user_id } = payload;
    const company_id: (string | undefined) = yield select(selectors.getManageAccessTableCompanyId);
    if (!company_id) return;
    const revoke_single_user_request = { 'user_ids': [revoke_single_user_id] };

    yield put(actions.setIsManageAccessTableLoading(true));
    const revoke_response = yield call(
      CompanyServices.deleteCompanyUser,
      company_id,
      revoke_single_user_request
    );
    if (revoke_response) yield put(ToastAction.setToast(revoke_response.message ?? ''));

    //fetcht updated list
    const limit: number = yield select(selectors.getLimit);
    const cursor: string = yield select(selectors.getCursor);
    const search: string = yield select(selectors.getSearch);
    const searchField: string = yield select(selectors.getSearchField);
    const order_by: string = yield select(selectors.getOrderBy);
    const order_by_direction: string = yield select(selectors.getOrderByDirection);
    const only_external: boolean = yield select(selectors.getIsExternal);
    const response = yield call(
      CompanyServices.fetchAllCompanyUsers,
      limit,
      cursor,
      search,
      searchField,
      order_by_direction,
      order_by,
      company_id,
      only_external
    );
    yield put(actions.setAllCompanyUser(response));

    //remove that user if it exists in revoke list
    const state_revoke_user_list: string[] = yield select(selectors.getRevokeUserList);
    if (state_revoke_user_list.some((item) => item === revoke_single_user_id)) {
      const updated_revoke_user_list = [...state_revoke_user_list];
      const index_of_updated_revoke_user = updated_revoke_user_list.findIndex(
        (item) => item === revoke_single_user_id
      );
      updated_revoke_user_list.splice(index_of_updated_revoke_user, 1);
      yield put(actions.setRevokeUserList([...updated_revoke_user_list]));
    }
  } catch (e: any) {
    yield put(actions.setError(e));
  } finally {
    yield put(actions.setIsManageAccessTableLoading(false));
  }
}

export function* giveCompanyAccess({ payload }: ManageAccessAction) {
  try {
    // give access
    const company_id: string | undefined = yield select(
      selectors.getManageAccessTableCompanyId
    );
    const user_list: string[] | undefined = yield select(selectors.getGiveAccessUserList);
    if (!user_list || !company_id) return;
    const give_access_user_list = { 'user_ids': user_list };

    yield put(actions.setIsManageAccessTableLoading(true));
    const give_access_response = yield call(
      CompanyServices.postCompanyUser,
      company_id,
      give_access_user_list
    );
    if (!give_access_response) return;

    //display success message
    yield put(ToastAction.setToast(give_access_response.message ?? ''));

    //fetcht updated list
    const limit: number = yield select(selectors.getLimit);
    const cursor: string = yield select(selectors.getCursor);
    const search: string = yield select(selectors.getSearch);
    const searchField: string = yield select(selectors.getSearchField);
    const order_by: string = yield select(selectors.getOrderBy);
    const order_by_direction: string = yield select(selectors.getOrderByDirection);
    const only_external: boolean = yield select(selectors.getIsExternal);
    if (!company_id) return;
    const response = yield call(
      CompanyServices.fetchAllCompanyUsers,
      limit,
      cursor,
      search,
      searchField,
      order_by_direction,
      order_by,
      company_id,
      only_external
    );
    yield put(actions.setAllCompanyUser(response));
    
    yield put(actions.setGiveAccessUserList([]));
  } catch (e: any) {
    yield put(actions.setError(e));
  } finally {
    yield put(actions.setIsManageAccessTableLoading(false));
  }
}

export function* revokeBulkUserAccess({ payload }: ManageAccessAction) {
  try {
    //revoke access
    const company_id: string | undefined = yield select(
      selectors.getManageAccessTableCompanyId
    );
    const user_list: string[] = yield select(selectors.getRevokeUserList);
    if (!company_id || user_list.length === 0) return;

    const revoke_user_list = { 'user_ids': user_list };
    yield put(actions.setIsManageAccessTableLoading(true));
    const revoke_response = yield call(
      CompanyServices.deleteCompanyUser,
      company_id,
      revoke_user_list
    );
    if (!revoke_response) return;

    //display success message
    yield put(ToastAction.setToast(revoke_response.message ?? ''));

    //fetcht updated list
    const limit: number = yield select(selectors.getLimit);
    const cursor: string = yield select(selectors.getCursor);
    const search: string = yield select(selectors.getSearch);
    const searchField: string = yield select(selectors.getSearchField);
    const order_by: string = yield select(selectors.getOrderBy);
    const order_by_direction: string = yield select(selectors.getOrderByDirection);
    const only_external: boolean = yield select(selectors.getIsExternal);
    const response = yield call(
      CompanyServices.fetchAllCompanyUsers,
      limit,
      cursor,
      search,
      searchField,
      order_by_direction,
      order_by,
      company_id,
      only_external
    );
    yield put(actions.setAllCompanyUser(response));

    //clear revoke list
    yield put(actions.setRevokeUserList([]));
  } catch (e: any) {
    yield put(actions.setError(e));
  } finally {
    yield put(actions.setIsManageAccessTableLoading(false));
  }
}

const sagas = [
  takeLatest(ManageAccessActionType.FETCH_ALL_COMPANY_USERS, fetchAllCompanyUsers),
  takeLatest(ManageAccessActionType.REVOKE_SINGLE_USER_ACCESS, revokeSingleUserAccess),
  takeLatest(ManageAccessActionType.GIVE_COMPANY_ACCESS, giveCompanyAccess),
  takeLatest(ManageAccessActionType.REVOKE_BULK_USER_ACCESS, revokeBulkUserAccess),
];

export default sagas;
